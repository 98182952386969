/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useEffect, useState } from "react";
import { Grid, IconButton, Popover } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { get } from "lib/fetch";
import { brandBlue } from "theme/colors";

interface IProps {
  anchorEl: any;
  type?: string;
  sysId: string;
  year: string | undefined;
  spawnPreview: (recordId: string, year: string) => any;
  onClose: () => any;
}

export const CourseListPopover = ({ anchorEl, type, sysId, year, spawnPreview, onClose }: IProps) => {
  const [allCourses, setAllCourses] = useState<any>([]);

  useEffect(() => {
    const getCourses = async () => {
      try {
        const courses = await get(`/v1/courserules/coursesFor?sysId=${sysId}&type=${type}`);
        setAllCourses(year ? courses.filter((c: any) => c.year === year) : courses);
      } catch (e) {
        console.log(e);
        setAllCourses([]);
      }
    };
    getCourses();
  }, [sysId, type, year]);

  const startPreview = useCallback(
    (course: any) => {
      onClose();
      spawnPreview(course.recordId, course.year);
    },
    [spawnPreview, onClose],
  );

  return (
    <Popover
      anchorEl={anchorEl}
      open={true}
      onClose={onClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      sx={{ marginTop: "4px" }}
    >
      <div style={{ padding: "2px 20px 20px 20px", width: "300px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h3>Select a course</h3>
          <IconButton onClick={onClose} style={{ marginTop: "4px" }}>
            <CloseIcon sx={{ fontSize: "24px" }} />
          </IconButton>
        </div>
        <Grid container spacing={0} alignContent={"start"}>
          {allCourses.map((course: any) => (
            <Grid item xs={12} key={course.sys_id} sx={{ paddingTop: "8px" }}>
              <span
                style={{
                  cursor: "pointer",
                  textDecoration: "underline",
                  color: brandBlue.toString(),
                }}
                onClick={() => startPreview(course)}
              >
                {course.name}
              </span>
            </Grid>
          ))}
        </Grid>
      </div>
    </Popover>
  );
};
